<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#B4E66E;"
      d="M416.542,364.475H17.356C7.77,364.475,0,356.705,0,347.119V86.78c0-9.586,7.77-17.356,17.356-17.356
	h399.186c9.586,0,17.356,7.77,17.356,17.356v260.339C433.898,356.705,426.128,364.475,416.542,364.475z"
    />
    <path
      style="fill:#A0D755;"
      d="M399.186,199.593c-71.89,0-130.169,58.279-130.169,130.17c0,12.041,1.765,23.645,4.825,34.712h142.7
	c9.585,0,17.356-7.771,17.356-17.356v-142.7C422.832,201.358,411.227,199.593,399.186,199.593z"
    />
    <path
      style="fill:#FFC850;"
      d="M130.169,199.593H60.746c-4.792,0-8.678-3.886-8.678-8.678v-52.068c0-4.792,3.886-8.678,8.678-8.678
	h69.424c4.792,0,8.678,3.886,8.678,8.678v52.068C138.847,195.708,134.962,199.593,130.169,199.593z"
    />
    <g>
      <path
        style="fill:#FFFFFF;"
        d="M121.492,271.186H52.068c-5.991,0-10.847-4.856-10.847-10.847c0-5.991,4.856-10.847,10.847-10.847
		h69.424c5.991,0,10.847,4.856,10.847,10.847C132.339,266.33,127.483,271.186,121.492,271.186z"
      />
      <path
        style="fill:#FFFFFF;"
        d="M329.763,323.254H52.068c-5.991,0-10.847-4.856-10.847-10.847s4.856-10.847,10.847-10.847h277.695
		c5.991,0,10.847,4.856,10.847,10.847S335.754,323.254,329.763,323.254z"
      />
    </g>
    <path
      style="fill:#E4EAF8;"
      d="M329.763,301.559h-57.595c-1.556,7.03-2.455,14.287-2.823,21.695h60.417
	c5.991,0,10.847-4.856,10.847-10.847S335.754,301.559,329.763,301.559z"
    />
    <path
      style="fill:#FFFFFF;"
      d="M225.627,271.186h-69.424c-5.991,0-10.847-4.856-10.847-10.847c0-5.991,4.856-10.847,10.847-10.847
	h69.424c5.991,0,10.847,4.856,10.847,10.847C236.475,266.33,231.618,271.186,225.627,271.186z"
    />
    <path
      style="fill:#E1A546;"
      d="M112.814,156.203H95.458v-26.034H78.102v69.424h17.356v-26.034h17.356
	c4.792,0,8.678-3.886,8.678-8.678C121.492,160.089,117.606,156.203,112.814,156.203z"
    />
    <circle style="fill:#FF507D;" cx="290.712" cy="160.542" r="39.051" />
    <circle style="fill:#FFC850;" cx="342.78" cy="160.542" r="39.051" />
    <path
      style="fill:#FF8C66;"
      d="M303.729,160.542c0,11.52,5.082,21.769,13.017,28.916c7.935-7.147,13.017-17.396,13.017-28.916
	s-5.082-21.769-13.017-28.916C308.811,138.774,303.729,149.022,303.729,160.542z"
    />
    <path
      style="fill:#FFFFFF;"
      d="M329.763,271.186h-69.424c-5.991,0-10.847-4.856-10.847-10.847c0-5.991,4.856-10.847,10.847-10.847
	h69.424c5.991,0,10.847,4.856,10.847,10.847C340.61,266.33,335.754,271.186,329.763,271.186z"
    />
    <path
      style="fill:#E4EAF8;"
      d="M283.056,271.186h46.707c5.991,0,10.847-4.856,10.847-10.847c0-5.991-4.856-10.847-10.847-10.847
	H296.85C291.563,256.222,286.951,263.482,283.056,271.186z"
    />
    <circle style="fill:#FFFFFF;" cx="399.186" cy="329.763" r="104.136" />
    <path
      style="fill:#FFDC64;"
      d="M399.186,216.949c-22.46,0-43.988,6.621-62.341,18.832l1.596-5.002
	c1.449-4.568-1.067-9.449-5.635-10.907c-4.551-1.449-9.449,1.076-10.907,5.635l-10.661,33.424c-0.882,2.788-0.314,5.831,1.534,8.102
	c1.652,2.043,4.136,3.212,6.737,3.212c0.289,0,0.585-0.017,0.873-0.042l34.907-3.534c4.771-0.483,8.246-4.737,7.762-9.509
	c-0.484-4.772-4.635-8.169-9.509-7.762l-6.909,0.701c15.485-10.245,33.63-15.794,52.553-15.794
	c52.635,0,95.458,42.823,95.458,95.458s-42.823,95.458-95.458,95.458c-4.797,0-8.678,3.881-8.678,8.678
	c0,4.797,3.881,8.678,8.678,8.678c62.204,0,112.814-50.61,112.814-112.814S461.39,216.949,399.186,216.949z"
    />
    <g>
      <path
        style="fill:#FFF082;"
        d="M362.373,435.881c-1.025,0-2.059-0.178-3.068-0.56c-5.635-2.136-11.161-4.754-16.407-7.78
		c-4.152-2.39-5.584-7.695-3.187-11.848c2.381-4.152,7.695-5.593,11.848-3.187c4.45,2.559,9.118,4.779,13.881,6.577
		c4.483,1.695,6.746,6.704,5.051,11.187C369.178,433.745,365.873,435.881,362.373,435.881z"
      />
      <path
        style="fill:#FFF082;"
        d="M321.339,407.602c-2.39,0-4.771-0.983-6.491-2.916c-4.034-4.543-7.711-9.416-10.923-14.475
		c-2.568-4.05-1.365-9.416,2.678-11.983c4.059-2.568,9.416-1.373,11.983,2.678c2.712,4.279,5.822,8.398,9.245,12.254
		c3.178,3.585,2.856,9.067-0.729,12.254C325.449,406.882,323.39,407.602,321.339,407.602z"
      />
      <path
        style="fill:#FFF082;"
        d="M298.094,363.55c-3.89,0-7.433-2.636-8.415-6.584c-1.458-5.864-2.441-11.899-2.932-17.923
		c-0.382-4.779,3.178-8.966,7.949-9.356c4.746-0.34,8.967,3.178,9.356,7.949c0.407,5.102,1.245,10.194,2.474,15.144
		c1.153,4.652-1.678,9.356-6.331,10.517C299.492,363.466,298.788,363.55,298.094,363.55z"
      />
      <path
        style="fill:#FFF082;"
        d="M298.009,313.729c-0.687,0-1.373-0.076-2.068-0.246c-4.652-1.136-7.509-5.839-6.373-10.492
		c1.432-5.855,3.356-11.652,5.712-17.229c1.865-4.407,6.958-6.475,11.372-4.61c4.415,1.865,6.475,6.958,4.61,11.372
		c-2,4.721-3.619,9.627-4.83,14.584C305.467,311.076,301.915,313.729,298.009,313.729z"
      />
    </g>
    <path
      style="fill:#FFC850;"
      d="M407.864,323.098v-27.294c10.893,1.804,17.356,6.63,17.356,9.552c0,4.797,3.881,8.678,8.678,8.678
	c4.797,0,8.678-3.881,8.678-8.678c0-13.863-14.423-24.711-34.712-27.137v-0.524c0-4.797-3.881-8.678-8.678-8.678
	c-4.797,0-8.678,3.881-8.678,8.678v0.524c-20.289,2.426-34.712,13.273-34.712,27.137c0,19.313,18.977,26.672,34.712,31.071v27.294
	c-10.893-1.804-17.356-6.63-17.356-9.552c0-4.797-3.881-8.678-8.678-8.678s-8.678,3.881-8.678,8.678
	c0,13.863,14.423,24.711,34.712,27.137v0.524c0,4.797,3.881,8.678,8.678,8.678c4.797,0,8.678-3.881,8.678-8.678v-0.524
	c20.289-2.425,34.712-13.273,34.712-27.137C442.576,334.857,423.6,327.498,407.864,323.098z M373.153,305.356
	c0-2.922,6.463-7.749,17.356-9.552v22.413C377.998,314.171,373.153,310.465,373.153,305.356z M407.864,363.722v-22.413
	c12.51,4.045,17.356,7.752,17.356,12.861C425.22,357.092,418.757,361.918,407.864,363.722z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
